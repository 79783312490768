import React, { useState, useCallback } from "react"
import Masonry from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

import Layout from "../components/layout"
import SEO from "../components/seo"

const photos = [
  {
    src: "https://i.imgur.com/J5LZNEK.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.imgur.com/nXa7HvE.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://i.imgur.com/mbqbF1i.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.imgur.com/rQArEV9.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.imgur.com/BClfzis.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.imgur.com/VXGZIOm.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://i.imgur.com/0qOgcEv.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://i.imgur.com/CNUZISZ.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://i.imgur.com/VlK7zCm.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://i.imgur.com/swnPlZ9.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.imgur.com/U4FUDEj.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://i.imgur.com/P56wTro.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.imgur.com/BiB6pvq.jpg",
    width: 5,
    height: 9,
  },
  {
    src: "https://i.imgur.com/nzGPTfP.jpg",
    width: 5,
    height: 9,
  },
  {
    src: "https://i.imgur.com/qS7RecU.png",
    width: 1,
    height: 1,
  },
  {
    src: "https://i.imgur.com/qlWkPQd.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.imgur.com/9qGPD57.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "https://i.imgur.com/sXJ42TR.jpg",
    width: 3,
    height: 4,
  },
]

const Gallery = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Layout>
      <SEO title="Gallery" />
      <div>
        <Masonry photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </Layout>
  )
}

export default Gallery
